import Newsletter from "./Componentes/Formulario";

function App() {
  return (
    <div className="main h-screen">
      <div className="flex flex-col justify-center w-full m-auto">
        <div className="header text-center">
          <h1 className="font-bold text-3xl border-2 sm:w-1/4 mx-auto my-4 rounded-xl p-2 border-yellow-500 w-1/2">luisetindev</h1>
        </div>
        <br />
        <div className="flex flex-col justify-center w-full">
          {/* <Link bgcolor="indigo-500" txcolor="white" msg="Mis cursos para aprender programacion!" link="http://patreon.com/luisetindev"></Link>
          <Link bgcolor="red" txcolor="black" msg="Youtube" link="http://youtube.com/@luisetindev"></Link>
          <Link bgcolor="rose-400" txcolor="black" msg="Instagram" link="http://instagram.com/luisetindev"></Link>
          <Link bgcolor="teal-600" txcolor="white" msg="TikTok" link="http://www.tiktok.com/@luisetindev_"></Link> */}
          <a href={"http://patreon.com/luisetindev"}
          className="mx-auto my-2 w-85% w-10/12 sm:w-1/3 text-center p-2 border-solid border-2  rounded-xl hover:scale-x-125 hover:scale-y-125 ease-out duration-300 hover:bg-indigo-500 hover:text-white border-indigo-500"
          >
              Mis cursos para aprender programacion!
          </a>
          <a href={"http://youtube.com/@luisetindev"}
          className="mx-auto my-2 w-85% w-10/12 sm:w-1/3 text-center p-2 border-solid border-2  rounded-xl hover:scale-x-125 hover:scale-y-125 ease-out duration-300 hover:bg-red-600 hover:text-black border-red-600"
          >
              Youtube
          </a>
          <a href={"http://instagram.com/luisetindev"}
          className="mx-auto my-2 w-85% w-10/12 sm:w-1/3 text-center p-2 border-solid border-2  rounded-xl hover:scale-x-125 hover:scale-y-125 ease-out duration-300 hover:bg-rose-400 hover:text-black border-rose-400"
          >
              Instagram
          </a>
          <a href={"http://tiktok.com/@luisetindev_"}
          className="mx-auto my-2 w-85% w-10/12 sm:w-1/3 text-center p-2 border-solid border-2  rounded-xl hover:scale-x-125 hover:scale-y-125 ease-out duration-300 hover:bg-teal-600 hover:text-white border-teal-600"
          >
              Tiktok
          </a>
          <Newsletter></Newsletter>
        </div>
      </div>
    </div>
  );
}

export default App;
