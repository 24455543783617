import { useState } from "react";

function Formulario()
{
    const [correo, setCorreo] = useState(null);
    const [nombre, setNombre] = useState(null);
    const [error, setError] = useState(null);
    const [msg, setMsg] = useState(null);



    function vaciarInputs()
    {
        const inputCorreo = document.querySelector("#email")
        const inputNombre = document.querySelector("#nombre")
        inputCorreo.value = ""
        inputNombre.value = ""
    }

    const handleNombreChange = (e) => {
        setNombre(e.target.value)
    }
    const handleCorreoChange = (e) => {
        setCorreo(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e.target)
        
        fetch("https://api.luisetindev.com/newsletter", {
            method: "POST",
            body: JSON.stringify({"nombre": nombre, "correo": correo})
        })
        .then(resp => {
            console.log(resp.json())

            if(resp.status !== 200){
                setMsg(null)
            }

            if(resp.status === 422){
                setError('El correo no es válido')
            }
            else if(resp.status === 404){
                setError('Error 404. Te agradeceria si me avisas del error')
            }
            else if(resp.status === 409){
                setError('El correo ya está registrado. No te preocupes ')
            }
            else if(resp.status === 200){
                setMsg('Correo registrado satisfactoriamente! Gracias')
                setError(null)
                vaciarInputs()
            }
            else{
                setError('Error desconocido. Te agradeceria si me avisas del error')
            }
            console.log(error)
        })
    }

    return(
        <div className="flex flex-col justify-center px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm my-2">
                <h2 className="mb-2 mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">¿Consejo diario gratuito?</h2>
                <p className="text-center text-gray-500 mb-2">Se el primero en enterarse cuando saque cosas nuevas, recibe consejos sobre programación diariamente y también te comparto mis pensamientos! </p>
            </div>
            

            <div className="flex flex-column">
                    <form onSubmit={handleSubmit} 
                    className="space-y-2 w-full" action="#" method="POST">
                            
                        <div className={`sm:w-1/4 mx-auto`}>
                            {error && <p className="text-red-500">{error}</p>}
                            {msg && <p className="text-green-500">{msg}</p>}

                        </div>

                        <div className="sm:w-1/4 mx-auto">
                            <label for="nombre" className="block text-sm font-medium leading-6 text-gray-900">Nombre: </label>
                            <input onChange={handleNombreChange} required
                            id="nombre" type="text" className="w-full px-2 py-1 border solid border-2 border-black rounded" />
                        </div>

                        <div className="sm:w-1/4 mx-auto">

                            <label for="email" className="block text-sm font-medium leading-6 text-gray-900">Correo Electrónico: </label>
                            <input onChange={handleCorreoChange} required
                            id="email" type="text" className="w-full px-2 py-1 border solid border-2 border-black rounded" />
                        </div>
                        <div className="sm:w-1/4 mx-auto">
                            <input type="checkbox" id="acepto" className="mx-2" required/>
                            <label htmlFor="acepto">Doy consentimiento para recibir emails</label>
                            <p className="text-gray-400 text-center">(Tu email estará seguro)</p>
                        </div>
                        <div>
                        </div>

                        <div>
                            <button type="submit" className="flex mx-auto w-1/3 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 my-4">Mantenerme informado</button>
                        </div>
                    </form>
                </div>
        </div>
        );
}


export default Formulario;